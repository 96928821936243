import "./about.css"
import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";

export default function About(props) {
    useEffect(() => {
        document.title = "Smishtank: About Us"
    }, []);

    return (
        <div className="about">
            <div className="aboutContainer">
               <h2 className="Titleblock">
                    Information Sheet
               </h2>
               <Paper>
                    <div className="aboutContainerInner">
                        <div>
                            {/* <h2>Identification of Researchers:</h2>
                            This reasearch is being conducted by professor Muhammad Lutfor Rahman and student researcher Daniel Timko. 
                            We are with the California State University, San Marcos. */}
                        </div>
                        <div>
                            <h2>
                                Purpose of the website:
                            </h2>
                            Smishtank is a community-based SMS Phishing ( Smishing ) submission and verification system. 
                            The users submit suspected SMS and other users vote for the legitimacy of SMS phishing. 
                            Together, we fight for Smishing. Please submit and verify an SMS phish.
                        </div>
                        {/* <div>
                            <h2>
                                Payment for Participation in Research:
                            </h2>
                            As a token for participation, we will be awarding a 50$ gift card to one random submission out of every 1000 submissions. 
                            In order to participate, submit a SMS phishing message through our <a class="SmishLinks" href="/addsmish">AddSmish Page</a>. 
                            We will announce the winner(s) in May 2022, declaring the post(s) that have won an  amazon giftcard. 
                            In order to collect the gift card you must contact us and identify that you submitted the winning message. 
                            Thank you for your submissions.
                        </div> */}
                        <br />
                    </div>
               </Paper>
               
            </div>
        </div>
    )
}