import React from 'react';
import "./home.css"
import { makeStyles, alpha } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import PhishTable from '../../components/PhishTable';
// import { AppBar } from '@material-ui/core';
// import { Tabs } from '@material-ui/core';
// import { Tab } from '@material-ui/core';
import { useEffect } from 'react';
// import { Sms } from '@material-ui/icons';
// import { Email } from '@material-ui/icons';
// import { Web } from '@material-ui/icons';
  
  const styles = makeStyles(theme => ({
    wrapper: {
      margin: "auto",
      textAlign: "center",
      [theme.breakpoints.up('sm')]: {
        width: "95%",
      },
      [theme.breakpoints.up('md')]: {
        width: "90%",
      },
      [theme.breakpoints.up('lg')]: {
        width: "85%",
      },
      [theme.breakpoints.up('xl')]: {
        width: "75%",
      },
    },
    gridStyle: {
      margin: "20px auto",
    },
    bigSpace: {
      marginTop: "5rem"
    },
    littleSpace:{
      textAlign: "left",
      marginLeft: "0.5rem",
      marginTop: "0.5rem",
    },
    smishSubtitle: {
      textAlign: "center",
      marginLeft: "0.5rem",
      marginTop: "0.3rem",
      [theme.breakpoints.up('xs')]: {
        fontSize: "x-small",
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: "small",
      },
      [theme.breakpoints.up('md')]: {
        fontSize: "medium",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "medium",
      },
    },
    tabBar:{
      paddingTop: "0.25rem",
      background: "#1976d2",
      '& .MuiTab-textColorInherit.Mui-selected': {
        color: "#F7B905",
      },
    },
    tabItem: {
        cursor: "pointer", 
        color: "inherit",
        fontWeight: "Bold",
        textDecoration: "none",
        "&:hover": {
            color: "#F7B905",
            fontWeight:"bolder",
            backgroundColor: alpha(theme.palette.common.white, 0.10),
        },
    },
    appBarMargins: {
      marginBottom:"8px",
    },
  }));

export default function Home() {
  // const [tabType,setTab]=React.useState(0);
  const classes = styles();
  // const handleTabs=(e,val)=>{
  //   setTab(val)
  // }
  useEffect(() => {
    document.title = "Smishtank: Home"
  }, []);
  return (
      <div className="home">
          <div className={classes.wrapper}>
              <Grid className={classes.gridStyle} item xs={11}>
              <Typography variant="h4" color="primary">
                <span style={{ color: 'red' }}>Smishing Analysis Tool <span style={{ fontSize: 'small' }}>BETA</span></span>
              </Typography>
                {/* <AppBar className={classes.appBarMargins} position="static"> */}
                  {/* <Tabs 
                    variant="fullWidth" 
                    value={tabType} 
                    indicatorColor="secondary"
                    textColor="inherit"
                    onChange={handleTabs}
                    className={classes.tabBar}
                  >
                    <Tab className={classes.tabItem} icon={<Sms />} label="SMS" /> */}
                    {/* <Tab className={classes.tabItem} icon={<Email />} label="Email" /> */}
                    {/* <Tab className={classes.tabItem} icon={<Web />} label="Social Media" /> */}
                  {/* </Tabs>
                </AppBar> */}
                {/* <TabPanel value={tabType} index={0}> */}
                  <PhishTable type="smish"/>
                  {/* </TabPanel> */}
                {/* <TabPanel value={tabType} index={1}><PhishTable type="email"/></TabPanel> */}
                {/* <TabPanel value={tabType} index={2}><PhishTable type="url"/></TabPanel> */}
      
              </Grid>
          </div>
      </div>
  )
}

// function TabPanel(props)
// {
//   const {children,value,index}=props;
//     return(<div>
//       {
//         value===index && (
//           <span>{children}</span>
//         )
//       }
//     </div>
//     )
// }