import "./terms.css"
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Email } from "@material-ui/icons";
import { ListItem, Paper, List, Box } from "@material-ui/core";

export default function Terms(props) {
    useEffect(() => {
        document.title = "Smishtank: Terms of Use"
    }, []);

    return (
        <div className="terms">
            <div className="termsContainer">
                <h2 className="Titleblock">
                    Terms of Use
                </h2>
                <Paper>
                    <div className="termsContainerInner">
                        <div>
                            <h2>How to cite</h2>
                            If you use our dataset please cite the following paper(s): <br /><br />
                            <Box sx={{ bgcolor: 'text.secondary', color: 'white', p: 2 }}>
                                {"@inproceedings{timko2023commercial,"} <br />
                                {"title={Commercial Anti-Smishing Tools and Their Comparative Effectiveness Against Modern Threats},"} <br />
                                {"author={Timko, Daniel and Rahman, Muhammad Lutfor},"} <br />
                                {"booktitle={Proceedings of the 16th ACM Conference on Security and Privacy in Wireless and Mobile Networks},"} <br />
                                {"pages={1--12},"} <br />
                                {"year={2023}"} <br />
                                {"}"}
                            </Box>
                        </div>
                        <div>
                            <h2>Smishtank Terms of Service:</h2>
                            These Terms of Service govern your access to and use of our services, including but not limited to the use of the https://smishtank.com website, SMS, APIs and any information, images or material uploaded to or appearing on the smishtank website.
                            By using this Service you agree to be bound by these Terms and all conditions contained herein as well as any and all other operating rules or policies that are published on this website.
                            Our service is available exclusively to individuals who are at least 18 years old. If you do not qualify, please do not attempt to use our services.
                            If you accept these terms on behalf of a company or other legal entity, you act as a substitute for this entity and authorize that it is bound to these terms of services.

                        </div>
                        <div>
                            <h2>Privacy Policy</h2>
                            Please review our Privacy Policy: <Link to="/privacy">https://smishtank.com/privacy</Link>. By using our service, you agree to be bound by our Privacy policy,
                            which is incorporated into these Legal Terms by this reference. For questions or conerns about our Privacy Policy, or to report privacy related problems, please contact us at:
                            <br />
                            <span className="label-align"><a href="mailto:mlrahman@csusm.edu?subject=Smishtank Feedback"><b>mlrahman@csusm.edu</b></a></span><Email className="messageIcon" />
                        </div>
                        <div>
                            <h2>Content on the Service</h2>
                            We hold the right to remove or edit your content submitted to this website.
                            Although we maintain no obligation to monitor any submissions, we reserve the right to remove any content submitted to the site at any time without notice if in our opinion we consider such submissions to be harmful or breach of these Terms of Use.
                            Additionally, you may contact us to report any harmful content, our contact information can be found at <Link to="/contact">https://smishtank.com/contact</Link>.
                        </div>
                        <div>
                            <h2>Digital Millenium Copyright Act("DMCA")</h2>
                            Smishtank.com respects the intellectual property rights of others. Per the DMCA, Smishtank.com will respond expeditiously to claims of copright infringement on the Site if submitted to our contact address found at <Link to="/contact">https://smishtank.com/contact</Link>.
                            We will take whatever action is deemed appropriate within its sole discrtion, including the removal of the allegedly infringing materials of copyright protected content.
                            <br />
                            If you believe that your intellectual property rights have been violated by content on our website, please provide the following information to the contact address found above.
                            <br />
                            <List dense>
                                <ListItem>
                                    1. A description of the copyrighted work or other intellectual property that you claim has been infringed.
                                </ListItem>
                                <ListItem>
                                    2. A description of where the material that you claim is infringing is located on the Site.
                                </ListItem>
                                <ListItem>
                                    3. An address, telephone number, and email address where we can contact you and, if different, an email address where the alleged infringing party, if not Smishtank.com, can contact you.
                                </ListItem>
                                <ListItem>
                                    4. A statement that you have a good-faith belief that the use is not authorized by the copyright owner or other intellectual property rights owner, by its agent, or by law.
                                </ListItem>
                                <ListItem>
                                    5. A statement by you under penalty of perjury that the information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the owner's behalf.
                                </ListItem>
                                <ListItem>
                                    6. Your electronic or physical signature.
                                </ListItem>
                            </List>

                        </div>
                        <div>
                            <h2>Account</h2>
                            As a condition to use some of our services, you may be required to create an account by providing a username and password.
                            You are responsible for maintaining your account information and safeguarding access to that account.
                            Additionally, you are responsible for your use of our services and any content your provide. This includes its compliance with laws, rules and regulations.
                            As such, you should only submit SMS messages to smishtank that you are comfortable publicly sharing.
                        </div>
                        {/* <div>
                            <h2>Creative Commons</h2>
                            <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
                                <img alt="Creative Commons License" className="creativeCommonsImg" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" />
                            </a>
                            <br />
                            This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>.
                        </div>
                        <br /> */}
                        <br />
                    </div>
                </Paper>

            </div>
        </div>
    )
}