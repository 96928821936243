import React from 'react';
import { WhoisItem, WhoisItemArray } from './WHOISItem';
import { Paper, Grid } from "@material-ui/core";

function WhoisResults({ wHOISResults }) {
  if (!wHOISResults) {
    return <p>WHOIS Information is currently unavailable for the message</p>; // If wHOISResults is false, render nothing
  }
  return (
    <div>
      <div className="msgSection">
        <span className="msgSectionTitle">
          <label>Domain WHOIS Results:</label>
        </span>
      </div>
      <Paper className="paperStyling">
        <Grid container spacing={1}>
          <WhoisItem data={wHOISResults.registrar} label="Registrar" />
          <WhoisItem data={wHOISResults.name} label="Name" />
          <WhoisItemArray data={wHOISResults.nameserver} label="Nameserver" />
          <WhoisItem data={wHOISResults.ip} label="IP" />
          <WhoisItem data={wHOISResults.created} label="Created" />
          <WhoisItem data={wHOISResults.lastUpdate} label="Last Update" />
        </Grid>
      </Paper>
      {wHOISResults.owner && Array.isArray(wHOISResults.owner) ? (
        <div>
          <div className="msgSection">
            <span className="msgSectionTitle">
              <label>Domain Ownership:</label>
            </span>
          </div>
          <Paper className="paperStyling">
            <Grid container spacing={1}>
              {Object.entries(wHOISResults.owner[0]).map(([key, value]) => (
                <WhoisItem data={value} label={key} key={key} />
              ))}
            </Grid>
          </Paper>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default WhoisResults;