import "./register.css"
import CustomBtn from '../../components/CustomBtn';
import { Lock, Person, Email } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import axios from "axios";

const createUser = 'https://744td226oe.execute-api.us-west-1.amazonaws.com/prod/register'

export default function Register() {
    useEffect(() => {
        document.title = "Smishtank: Register"
    }, []);
    const [userName, setName] = useState('');
    const [password, setPass] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const submitHandler = (event) => {
        event.preventDefault();
        if (userName.trim() === '' || password.trim() === '' || email.trim() === '')
        {
            setMessage("All input fields are required.");
            return;
        }
        if (userName.trim() === 'Anonymous')
        {
            setMessage("That name is reserved, please try another.")
            return;
        }

        const requestConfig = {
            headers: {
                'x-api-key': 'wF6E9Pe3fjUeKALCFfvo9op3yF7dG5K3A4TU94Qf'
            }
        }
        const requestBody = {
            username: userName,
            password: password,
            email: email
        }
        axios.post(createUser,requestBody,requestConfig).then(response => {
            setMessage('User Added');
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403)
            {
                setMessage(error.response.data.message);
            } else {
                setMessage('The backend server is currently down, please try again later')
            }
        })
    }

    return (
        <div className="register">
            <div className="RegisterFormContainer">
                <span className="messageDetails">Registration</span>
                <form onSubmit={submitHandler} className="loginForm" id="login">
                    <div className="messageInfo">
                        <label>
                            <Person className="messageIcon" />
                            <span className="label-align">Username</span>
                        </label>
                        <input 
                            value={userName}
                            type="text" 
                            placeholder="username" 
                            className="userName"
                            maxlength="20"
                            onChange={event => setName(event.target.value)}
                        />
                    </div>
                    <div className="messageInfo">
                        <label>
                            <Lock className="messageIcon" />
                            <span className="label-align">Password</span>
                        </label>
                        <input 
                            value={password}
                            type="password" 
                            placeholder="pass" 
                            className="userPass"
                            onChange={event => setPass(event.target.value)}
                        />
                    </div>
                    <div className="messageInfo">
                        <label>
                            <Email className="messageIcon" />
                            <span className="label-align">Email</span>
                        </label>
                        <input 
                            value={email}
                            type="email" 
                            placeholder="email" 
                            className="email"
                            onChange={event => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="SmishTitleContainer">
                        <Button component={Link} to="/login" variant="contained" color="primary" className="addSmishTitle">
                            Login
                        </Button>
                        <CustomBtn txt="Submit" form="submitSmish" /> 
                    </div> 
                </form>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    )
}