import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core';

const styles = makeStyles(theme => ({
    specialChipStyle: {
        fontSize: 'small',
        textAlign: 'center',
        paddingRight: 4,
        paddingLeft: 5,
        paddingTop: 4,
        paddingBottom: 4,
        [theme.breakpoints.up('xs')]: {
          fontSize: 'xx-small',
          overflowWrap: 'anywhere',
          paddingLeft: 0,
          '& .MuiChip-label': {
            // paddingLeft: 7,
            paddingRight: 6,
            maxWidth: '70px', // Apply width only when screen size is 'xs'
          },
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: 'x-small',
          '& .MuiChip-label': {
            maxWidth: '100px',
            paddingLeft: 9,
            paddingRight: 9,
          },
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 'small',
          '& .MuiChip-label': {
            maxWidth: '250px',
            paddingLeft: 9,
            paddingRight: 9,
          },
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 'medium',
          '& .MuiChip-label': {
            maxWidth: '500px',
            paddingLeft: 12,
            paddingRight: 12,
          },
        },
      },
  
  }));

const TableExtra = ({ object, property, avatar, icon, value }) => {
  // Check if the property exists in the object
  const propertyExists = object.hasOwnProperty(property);
  const classes = styles();

  // Conditionally render the property if it exists
  return propertyExists ? (
    <Chip label={`${value}${object[property]}`} icon={icon} avatar={avatar} color="primary" variant="outlined" className={classes.specialChipStyle} />
  ) : null;
};

export default TableExtra;