import "./smish.css";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import VirusTotalResults from "../../components/VTResults";
import URLScanResults from "../../components/URLScanResults";
import WhoisResults from "../../components/WHOISResults";
import DisplayMainSmishContent from "../../components/DisplayMainSmishConent";
import FullVotingComponent from "../../components/FullVotingComponent";
import { getUser } from "../../service/AuthService";
import { Box } from "@material-ui/core";
import { AppBar } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageContent: {
    width: "calc(100% + 32px)",
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      margin: theme.spacing(0),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
    },
  },
  tabBar: {
    paddingTop: "0.25rem",
    background: "#163b50",
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#F7B905",
    },
  },
  tabItem: {
    cursor: "pointer",
    color: "inherit",
    fontWeight: "Bold",
    textDecoration: "none",
    "&:hover": {
      color: "#F7B905",
      fontWeight: "bolder",
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "0.60rem",
      padding: "6px 8px",
      minWidth: "50px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.85rem",
      padding: "6px 12px",
    },
  },
  appBarMargins: {
    margin: "5px 0",
  },
}));

export default function Smish(login) {
  const [tabType, setTab] = React.useState(0);
  const handleTabs = (e, val) => {
    setTab(val);
  };
  const { smishId } = useParams();
  const getSmish =
    "https://744td226oe.execute-api.us-west-1.amazonaws.com/prod/smish";
  const updateSmishVote =
    "https://744td226oe.execute-api.us-west-1.amazonaws.com/prod/vote";
  const [message, getMessage] = React.useState(null);
  const [foundURL, setFoundURL] = React.useState(null);
  const [vTResultsObj, setVTResultsObj] = React.useState(null);
  const [wHOISResults, setWHOISResults] = React.useState(null);
  const [urlScanObj, setURLScanObj] = React.useState(null);
  const [errmessage, setErrMessage] = useState(null);
  const [voted, setVoted] = useState(false);
  const [VTTextColor, setVTTextColor] = useState("black");
  const [upvotes, setUpvotes] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [downvotes, setDownvotes] = useState(0);
  const [imageExists, setImageExists] = useState(true); // Initialize as false
  const classes = styles();
  let props = {
    upvotes: upvotes,
    downvotes: downvotes,
  };
  useEffect(() => {
    document.title = `Smishtank: Smish#${smishId}`;
    let isMounted = true; // Variable to track if the component is mounted
    const getaMessage = () => {
      const user = getUser();
      const name = user !== "undefined" && user ? user.username : "";
      let messageID = parseInt(smishId.substring(0, smishId.length));
      let message = 0;
      const requestConfig = {
        headers: {
          "x-api-key": "wF6E9Pe3fjUeKALCFfvo9op3yF7dG5K3A4TU94Qf",
        },
      };
      const requestBody = {
        message: message,
        messageID: messageID,
        username: name,
      };
      if (isMounted) {
        axios
          .post(getSmish, requestBody, requestConfig)
          .then(async (res) => {
            const messageData = res.data;
            if (messageData.smish.url) {
              setFoundURL(messageData.smish.url);
            }
            setFoundURL(messageData.smish.url);
            if (messageData.smish.voted) {
              setVoted(true);
              setFeedback("Thank you for voting");
            }
            if (messageData.smish.VTResults) {
              if (messageData.smish.VTResults.detected > 0) {
                setVTTextColor("red");
              }
              setVTResultsObj(messageData.smish.VTResults);
            }
            if (messageData.smish.WHOISResults) {
              setWHOISResults(messageData.smish.WHOISResults);
            }
            if (messageData.smish.urlScan) {
              setURLScanObj(messageData.smish.urlScan);
            }
            getMessage(messageData.smish);
            setUpvotes(messageData.smish.upvotes);
            setDownvotes(messageData.smish.downvotes);
            // await checkImageExistsAndSetState(
            //   `https://smishtank-direct-upload-s3.s3.us-west-1.amazonaws.com/images/${smishId}`
            // );
          })
          .catch((error) => {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              setErrMessage(error.response.data.message);
            } else {
              setErrMessage("This message does not exist in our database");
            }
          });
      }
      return () => {
        isMounted = false; // Update the mounted status to false on unmount
      };
    };
    getaMessage();
  }, [smishId]);
  async function checkImageExistsAndSetState(imageUrl) {
    try {
      const response = await axios.head(imageUrl);
      const imageExists = response.status === 200; // If status is 200, the image exists.
      setImageExists(imageExists);
    } catch (error) {
      setImageExists(false); // If there's an error (e.g., 404), the image doesn't exist.
    }
  }

  function handleVote(type) {
    const user = getUser();
    const name = user !== "undefined" && user ? user.username : "";

    const requestConfig = {
      headers: {
        "x-api-key": "wF6E9Pe3fjUeKALCFfvo9op3yF7dG5K3A4TU94Qf",
      },
    };
    const requestBody = {
      message: message.message,
      messageID: message.messageID,
      user: name,
      vote: type,
    };
    axios
      .post(updateSmishVote, requestBody, requestConfig)
      .then((res) => {
        setFeedback("Thank you for voting");
        if (type === "up") {
          setUpvotes(upvotes + 1);
        } else {
          setDownvotes(downvotes + 1);
        }
        setVoted(true);
      })
      .catch((error) => {
        if (
          error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 404
        ) {
          setFeedback(error.response.data.message);
        } else if (error.response.status === 402) {
          setFeedback(error.response.data.message);
          setVoted(true);
        } else {
          setFeedback("An error occurred while trying to commit your vote");
        }
      });
  }

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <span>{children}</span>}</div>;
  }

  return (
    <div className="smish">
      <div className="smishContainer">
        <Paper className={classes.pageContent}>
          {errmessage && <p className="message">{errmessage}</p>}
          {message ? (
            <>
              <div className="msgSection">
                <span className="msgSectionTitle">Message Info</span>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={8}>
                  <div className="pageTitle">Message Details</div>
                </Grid>
                <Grid item xs={4}>
                  <div>{smishId}</div>
                </Grid>
              </Grid>
              <Box
                component="div"
                sx={{ whiteSpace: "normal", textAlign: "left" }}
              >
                <Paper className="paperStyling">
                  <span className="messageContentField">
                    {message.messageContent}
                  </span>
                </Paper>
              </Box>
              <AppBar className={classes.appBarMargins} position="static">
                <Tabs
                  variant="fullWidth"
                  value={tabType}
                  indicatorColor="secondary"
                  textColor="inherit"
                  className={classes.tabBar}
                  onChange={handleTabs}
                  centered
                >
                  <Tab className={classes.tabItem} label="Summary" />
                  <Tab className={classes.tabItem} label="Virus" />
                  <Tab className={classes.tabItem} label="WHOIS" />
                  <Tab className={classes.tabItem} label="URL" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabType} index={0}>
                <DisplayMainSmishContent
                  foundURL={foundURL}
                  message={message}
                />
                <FullVotingComponent
                  props={props}
                  login={login}
                  handleVote={handleVote}
                  voted={voted}
                  feedback={feedback}
                  imageExists={imageExists}
                  smishId={smishId}
                />
              </TabPanel>
              <TabPanel value={tabType} index={1}>
                <div className="VTResults">
                  <VirusTotalResults
                    url={foundURL}
                    vTResultsObj={vTResultsObj}
                    VTTextColor={VTTextColor}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabType} index={2}>
                <div className="VTResults">
                  <WhoisResults wHOISResults={wHOISResults} />
                </div>
              </TabPanel>
              <TabPanel value={tabType} index={3}>
                <div className="VTResults">
                  <URLScanResults url={foundURL} urlScanObj={urlScanObj} />
                </div>
              </TabPanel>
            </>
          ) : null}
        </Paper>
      </div>
    </div>
  );
}
