import "./learn.css"
import React, { useEffect } from "react";
import { styled } from "@material-ui/styles";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Paper } from "@material-ui/core";

export default function Learn(props) {
    useEffect(() => {
        document.title = "Smishtank: Learn More"
    }, []);

    const MuiAccordionSummary = styled((props) => (
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
          {...props}
        />
        ))(({ theme }) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(200, 255, 255, .15)'
            : 'rgba(0, 0, 0, .03)',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
    }));

    return (
        <div className="learn">
            <div className="learnContainer">
               <h2 className="Titleblock">
                    Learn More
               </h2>
               <Paper className="learnPaperStyling">
                    <div className="learnInfoContainer">
                        <h3>Topics on Smishing</h3>
                        <hr />
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                                <Typography><b>What is Smishing?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Smishing is a compound word which combines SMS and phishing. 
                                    Phishing is the fraudulent act of trying to trick a user into giving up personally identifying information. 
                                    SMS refers to the Short Message Service that we typically use for texting.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            >
                                <Typography><b>How do I identify Smishing?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <h4> Smishing attackers employ an array of tactics to deceive their targets, here are some common signs to look for:</h4>
                                    <Accordion>
                                        <MuiAccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography><b>Does it request personal information</b></Typography>
                                        </MuiAccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            Typically, the goal of a Smish attack is to extract personal information from you, such as passwords or credit card details. Is the SMS message asking you to provide these details? If so, it is likely to be a Smish attack.
                                        </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <MuiAccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1b-content"
                                        id="panel1b-header"
                                        >
                                        <Typography><b>Check the URLs for discrepencies</b></Typography>
                                        </MuiAccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            If the SMS message contains a link, then look carefully at the URL. A common Smishing tactic is to trick a target into visiting a fraudulent website through a URL that is forged to look authentic. 
                                            These websites are often made to look like an exact copy of a well known authentic site; however, the URL will be slightly different. If you notice the URL is off, then thats a sign that the message is likely a smish attack.
                                            A tactic that Smishing scammers use to hide this link URL is to use link shorteners. Never click on shortened links that you receive over text message. 
                                            <br /><br />
                                            Ask yourself, is this the correct URL for this website? 
                                            If you know the site it is asking you to visit, you can look it up on a search engine and compare the link with the one they sent you.
                                        </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <MuiAccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header"
                                        >
                                        <Typography><b>Check the sender</b></Typography>
                                        </MuiAccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                                A common Smishing tactic is to try and pursuade you that the message is coming from a credible company or legitimate agency.
                                                As standard practice, legitimate organizations don't ask you for your personally identifying information over text. Extra suspicion should be used around unexpected messages coming from an unknown number claiming to be a known organization. 
                                                Attackers can pretend to be a member of a legitimate organization and claim to have information about an unexpected access or charges on your account to try and convince you visit a website or call a number. 
                                                If its a known company, you can usually look up and call their customer service number to verify if they sent you the SMS.

                                        </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <MuiAccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                        >
                                            <Typography><b>Check for calls to action</b></Typography>
                                        </MuiAccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Smishing scams often use promises of rewards or punishment if you don't respond immediately. 
                                                Examples of fear-based attacks can include claiming that your bank account has been breached, or that a purchase has been made using your credit card.
                                                Examples of reward-based attacks can include claims that you have won a prize, or offers of large sums of money.
                                                Does this SMS message convey a sense of urgency or panic? Typically, a Smish attacker wants you to act quickly without thinking too long about where or who you are sending your information to.
                                                
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <MuiAccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1e-content"
                                        id="panel1e-header"
                                        >
                                            <Typography><b>Check for urgency</b></Typography>
                                        </MuiAccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Does this SMS message convey a sense of urgency or panic? Typically, a Smish attacker wants you to act quickly without thinking too long about where or who you are sending your information to.
                                                Messages claiming to offer prizes, money or no interest credit cards if you respond within a short period of time are common smishing scams. 
                                                Additionally, smishing scams often use fear-based tactics such as claiming that your bank account has been breached, or that something has been purchased with your account. 
                                                
                                                
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            >
                                <Typography><b>Difference between spam and Smish?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Spam is defined as unwanted or unsolicited advertising messages. Smishing is a malicious SMS disguised as a trustworthy source to trick you into handing over personally identifying information. While spam can use some of the same tactics used by Smishing, they differ in that Smishing is a type of fraudulent communication that is designed to be malicious.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <h3>How to use this site</h3>
                        <hr />
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            >
                                <Typography><b>How do I create an account?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    In order to create an account you can go to the <a href="/register">Registration page located here</a>. You will need to provide a username, password and email address to create an account. Your username will be displayed on newly submitted Smish messages as the "Submitted By" name.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                            >
                                <Typography><b>How do I report a Smish?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    In order to report a Smish you can go to the <a href="/addsmish">ADD SMISH page here</a>. Smish can be submitted with or without an account. You will need to provide the message content, the time you received the SMS and a screenshot with your submission.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel6bh-content"
                            id="panel6bh-header"
                            >
                                <Typography><b>How do I vote on a Smish?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    In order to vote on a Smish, you must be logged in to your account. You can create an account in the <a href="/register">registration page located here</a>. Next, you will need to navigate to HOME from the menu and then select a Smish from the Smishtank list. You may only vote once per message.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <h3>Smishtank Data</h3>
                        <hr />
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel7bh-content"
                            id="panel7bh-header"
                            >
                                <Typography><b>Where does our data come from?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    All our smishing data comes from community submissions. If you have an example of a smishing message you have received, please consider submitting it to our <a href="/addsmish">ADD SMISH page here</a>.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel7bh-content"
                            id="panel7bh-header"
                            >
                                <Typography><b>What do I do if I see an inappropriate or incorrect submission?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Inappropriate or incorrect submissions are taken very seriously by our team. If you witness a submission that should not belong in the list then send us an email to the contact address located in the <a href="/contact">Contact Us page here</a>.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel8bh-content"
                            id="panel8bh-header"
                            >
                                <Typography><b>How is our data different from other Phishing feeds?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Our work is novel in that we are exclusively collecting Smishing messages. There are alternative sites for phishing websites and phishing emails.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <h3>Developer Infromation: API access</h3>
                        <hr />
                        <Accordion>
                            <MuiAccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel9bh-content"
                            id="panel9bh-header"
                            >
                                <Typography><b>How do we get access to the Smishtank data?</b></Typography>
                            </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    We are working on creating an API to publicly fetch the data in a variety of forms. 
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
               </Paper>
            </div>
        </div>
    )
}