import "./privacy.css"
import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";

export default function Privacy(props) {
    useEffect(() => {
        document.title = "Smishtank: Privacy"
    }, []);

    return (
        <div className="privacy">
            <div className="privacyContainer">
               <h2 className="Titleblock">
                    Smishtank: Privacy Policy
               </h2>
               <h5>Last updated on 3/23/2022</h5>
               <Paper>
                    <div className="privacyPolicyContainer">
                        <h3>Summary</h3>
                        <ul>
                            <li>We do not use cookies</li>
                            <li>If you register an account, your contact information will be stored securely and not shared.</li>
                            <li>SMS messages that you provide are collected and displayed publicly on our Smishing feed</li>
                        </ul>
                        
                        <h3>Collection And Use Of Information</h3>
                        We may collect the following kinds of information when you use this site:
                        <br />
                        <span className="emphasizedText">Information that you provide directly to us.</span> We store and secure login credentials for user accounts. This includes usernames, passwords and email addresses. 
                        We store and display SMS details provided through the <a href="/addsmish" >Add Smish page</a>.

                        <h3>IP Addressess</h3>
                        We do not collect IP address information.

                        <h3>Account Information</h3>
                        We provide an account system that allows for user user registration. In order to vote on whether a message is a Smishing message or not, a user must create and log in to their user account. Email addresses used for account creation will not be shared. Usernames are publicly visible and associated with the smish messages that are added.

                        <h3>Age Restriction</h3>
                        Smishtank is not directed to, nor does it knowingly collect any information from, children under the age of 18. If we learn that a child has provided us with information, we will delete it. 
                                           
                        <h3>Privacy Contact</h3>
                        Please direct your privacy concerns to this email: mlrahman@csusm.edu

                        <br />
                        <br />
                    </div>
               </Paper>
               
            </div>
        </div>
    )
}