import React from 'react'
import {Toolbar} from '@material-ui/core'
import {Button} from '@material-ui/core'
import { Box } from '@material-ui/core'
import { AppBar } from '@material-ui/core'
import {makeStyles, alpha} from '@material-ui/core/styles'
import LoginIcon from '@material-ui/icons/Input';
import { Link } from "react-router-dom";


const styles = makeStyles(theme => ({
    bar:{
        paddingTop: "0.25rem",
        background: "#1976d2",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
    },
    menuItem: {
        cursor: "pointer", 
        color: "inherit",
        fontWeight: "Bold",
        textDecoration: "none",
        flexGrow: 1,
        "&:hover": {
            color: "#F7B905",
            backgroundColor: alpha(theme.palette.common.white, 0.10),
        },
        [theme.breakpoints.up('xs')]: {
            fontSize: '.7rem',
            padding: 5, 
            "& .MuiButton-label .MuiButton-endIcon": {
                marginLeft: '2px',
                marginRight: '0px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.85rem',
            padding: 5,
            "& .MuiButton-label .MuiButton-endIcon": {
                marginLeft: '8px',
                marginRight: '-4px',
            },
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '1rem',
            padding: 10,
        },
    },
}));

function NavBar(props) {
    const classes = styles()
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar position="sticky" color="rgba(0, 0, 0, 0.87)" className={classes.bar}>   
                    <Button component={Link} to="/" color="inherit" className={classes.menuItem}>
                        Home
                    </Button>
                    <Button component={Link} to="/addsmish" color="inherit" className={classes.menuItem}>
                        Add Smish
                    </Button>
                    <Button component={Link} to="/people" color="inherit" className={classes.menuItem}>
                        People
                    </Button>
                    <Button component={Link} to="/dataset" color="inherit" className={classes.menuItem}>
                        Dataset
                    </Button>
                    {props.loggedin ? (
                        <Button type ="button" onClick={props.handleLogout} color="inherit" className={classes.menuItem} endIcon={<LoginIcon />}>
                            Logout
                        </Button>
                    ) : (
                        <Button component={Link} to="/login" color="inherit" className={classes.menuItem} endIcon={<LoginIcon />}>
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default NavBar