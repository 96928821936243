import * as React from 'react';
import TableExtra from './TableExtra';
import { styled, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table'
import { Grid } from "@material-ui/core";
import { Domain } from '@material-ui/icons';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { TableContainer } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import axios from 'axios';
import { useEffect } from 'react';
import { Backdrop } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import { TableFooter } from '@material-ui/core';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  TableHead: {
    fontSize: '1rem',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

const styles = makeStyles(theme => ({
  headerStyle: {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.up('xs')]: {
      fontSize: '.6rem',
      padding: 3,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      padding: 12,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      padding: 16,
    },
  },

  rowItemStyle: {
    "&:hover": {
      fontWeight: "bolder",
      color: "#1B4CAB !important",
      backgroundColor: 'rgba(194, 224, 255, 0.4) !important',
      borderColor: '1px solid #99CCF3 !important',
      cursor: 'pointer',
    },
  },

  sortLabelStyle: {
    color: 'white !important',
  },

  cellStyle: {
    fontSize: 'small',
    textAlign: 'left',
    paddingRight: 4,
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    [theme.breakpoints.up('xs')]: {
      fontSize: 'xx-small',
      overflowWrap: 'anywhere',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 'small',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'medium',
    },
  },

  extraCellStyle: {
    fontSize: 'small',
    textAlign: 'left',
    padding: 0,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    [theme.breakpoints.up('xs')]: {
      fontSize: 'xx-small',
      overflowWrap: 'anywhere',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 'small',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'medium',
    },
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },

  formattedCellStyle: {
    [theme.breakpoints.up('xs')]: {
      overflowWrap: 'break-word !important',
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'table-cell',
    },
  },

  rowCullStyle: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'table-cell',
    },
  },

  cellIDStyle: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    minWidth: '30px',
  },

  borderStyling: {
    borderRadius: '15px 15px 0px 0px'
  },

  centerStyling: {
    textAlign: 'center !important',
  },

  stripPadding: {
    padding: 0,
    margin: 0,
    textAlign: 'end',
  },

  smallTextStyling: {
    '& .MuiTablePagination-selectRoot': {
      [theme.breakpoints.up('xs')]: {
        margin: 0,
      },
    },
    '& .MuiTablePagination-actions': {
      [theme.breakpoints.up('xs')]: {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      [theme.breakpoints.up('xs')]: {
        padding: 4,
      },
    },
    '& .MuiTablePagination-caption': {
      [theme.breakpoints.up('xs')]: {
        fontSize: 'x-small',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 'medium',
      },
    },
    '& .MuiTablePagination-toolbar': {
      [theme.breakpoints.up('xs')]: {
        fontSize: 'smaller',
        paddingLeft: 3,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 'medium',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
    }
  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// function convertUnix(timestamp) {
//   return new Intl.DateTimeFormat('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp)
// }

function PhishTable(props) {
  const [page, setPage] = React.useState(0);
  const [messages, getMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getPhishesUrl = 'https://744td226oe.execute-api.us-west-1.amazonaws.com/prod/getsmish'
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = styles();
  const history = useHistory();
  const handleRowClick = (rowID) => {
    history.push(`/smish/${rowID.messageID}`)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const params = new URLSearchParams([['type', props.type]]);
    const getAllMessages = () => {
      axios.get(getPhishesUrl, { params })
        .then(res => {
          setLoading(false);
          const messageData = res.data;
          getMessages(messageData.smishes);
        }).catch(error => console.error(`Error: ${error}`))
    }
    getAllMessages();
  }, [props.type]);

  return (
    <Paper className={classes.borderStyling} sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className={classes.borderStyling}>
        <Table stickyHeader aria-label="sticky table" sx={{ borderRadius: '10px' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.headerStyle}>ID</StyledTableCell>
              <StyledTableCell className={classes.headerStyle}>Message</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((messages) => (
                <React.Fragment key={`main-${messages.messageID}`}>
                  <StyledTableRow onClick={() => handleRowClick(messages)} className={classes.rowItemStyle} key={`${messages.messageID}`}>
                    <StyledTableCell className={classes.cellIDStyle} component="th" scope="row">
                      {`${messages.messageID}`}
                    </StyledTableCell>
                    <StyledTableCell className={classes.cellStyle}>
                      {messages.messageContent.length > 350
                        ? messages.messageContent.substring(0, 347) + '...'
                        : messages.messageContent}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={`extra-${messages.messageID}`}>
                    <StyledTableCell className={classes.extraCellStyle} colSpan={2}>
                      {messages.sender ? (
                        <TableExtra object={messages} avatar={<Avatar>S</Avatar>} property="sender" value="" />
                      ) : null}
                      {messages.brand ? (
                        <TableExtra object={messages} avatar={<Avatar>B</Avatar>} property="brand" value="" />
                      ) : null}
                      {messages.VTResults ? (
                        <TableExtra object={messages.VTResults} avatar={<Avatar>VT</Avatar>} property="detected" value="" />
                      ) : null}
                      {messages.WHOISResults ? (
                        <TableExtra object={messages.WHOISResults} icon={<Domain />} property="registrar" value="" />
                      ) : null}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.stripPadding} colSpan={4}>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={messages.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className={classes.smallTextStyling}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Typography variant="h5" color="primary">
        Legend
      </Typography>
      <Grid container spacing={1}>
        <Grid item className="vtResults" xs={6} sm={3}>
          <Chip
            avatar={<Avatar>S</Avatar>}
            label="Sender"
            color="primary"
            variant="outlined"
          />
        </Grid>
        <Grid item className="vtResults" xs={6} sm={3}>
        <Chip
            avatar={<Avatar>B</Avatar>}
            label="Brand"
            color="primary"
            variant="outlined"
          />
        </Grid>
        <Grid item className="vtResults" xs={6} sm={3}>
        <Chip
            avatar={<Avatar>VT</Avatar>}
            label="VirusTotal Score"
            color="primary"
            variant="outlined"
          />
        </Grid>
        <Grid item className="vtResults" xs={6} sm={3}>
          <Chip
            icon={<Domain />}
            label="Domain"
            color="primary"
            variant="outlined"
          />
        </Grid>
      </Grid>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
}

export default PhishTable