import "./smishtankdataset.css";
import React, { useEffect } from "react";
import { Paper, Box } from "@material-ui/core";

export default function SmishtankDataset(props) {
  useEffect(() => {
    document.title = "Smishtank: Smishing Datasets Download";
  }, []);

  return (
    <div className="dataset">
      <div className="datasetContainer">
        <h2 className="Titleblock">Smishtank Datasets</h2>
        <Paper>
          <div className="datasetInfoContainer">
            <h2> Smishtank Dataset I</h2>
            <a href="analysisdataset.csv" download>Download CSV</a>
            <h2>How to cite</h2>
            If you use any data from smishtank.com, you must cite the following paper(s): 
            <h3>Dataset I Paper</h3>
            <Box sx={{ bgcolor: 'text.secondary', color: 'white', p: 2 }}>
                {"@article{timko2024smishing,"} <br />
                {"title={Smishing Dataset I: Phishing SMS Dataset from Smishtank.com},"} <br />
                {"author={Timko, Daniel and Rahman, Muhammad Lutfor},"} <br />
                {"booktitle={Proceedings of the Fourteenth ACM Conference on Data and Application Security and Privacy},"} <br />
                {"pages={289--294},"} <br />
                {"year={2024}"} <br />
                {"}"}
            </Box>
            <h3>Smishtank Paper</h3>
            <Box sx={{ bgcolor: 'text.secondary', color: 'white', p: 2 }}>
              {"@inproceedings{timko2023commercial,"} <br />
              {"title={Commercial Anti-Smishing Tools and Their Comparative Effectiveness Against Modern Threats},"} <br />
              {"author={Timko, Daniel and Rahman, Muhammad Lutfor},"} <br />
              {"booktitle={Proceedings of the 16th ACM Conference on Security and Privacy in Wireless and Mobile Networks},"} <br />
              {"pages={1--12},"} <br />
              {"year={2023}"} <br />
              {"}"}
            </Box>
            <br />
          </div>
        </Paper>
      </div>
    </div>
  );
}
