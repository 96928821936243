import React from 'react';
import Box from '@mui/material/Box'; // Import Box from Material-UI or your library
import { VoteBarChart, VoteButtons } from './VoteBarChart';

const FullVotingComponent = ({ props, login, handleVote, voted, feedback, imageExists, smishId }) => {
    return (
        <Box
            component="div"
            sx={{ whiteSpace: "normal", textAlign: "left", flexGrow: 1 }}
        >
            <div className="timeTitle">
                <div className="msgSection">
                    <span className="msgSectionTitle">
                        <label>Is this a SMS Phishing Attack?</label>
                    </span>
                </div>
            </div>
            <VoteBarChart {...props} />
            <VoteButtons
                login={login}
                handleVote={handleVote}
                voted={voted}
                feedback={feedback}
            />
            {imageExists ? (
                <>
                    <div className="msgSection">
                        <span className="msgSectionTitle">
                            Attached Screenshot:
                        </span>
                    </div>
                    <div className="msgInfo smishImg">
                        <img
                            className="messagePicture"
                            alt="phishing screenshot"
                            src={`https://smishtank-direct-upload-s3.s3.us-west-1.amazonaws.com/images/${smishId}`}
                        />
                    </div>
                </>
            ) : null}
        </Box>
    );
};

export default FullVotingComponent;