import * as React from 'react';
import { Paper } from '@material-ui/core';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';

function VoteBarChart(props) {
  return (
    <Paper>
      <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: ['Yes', 'No'],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: [props.upvotes,props.downvotes],
          },
        ]}
        // width={500}
        height={300}
      />
    </Paper>
  );
}

function VoteButtons({ login, handleVote, voted, feedback }) {
  return (
    <div>
      {login.loggedin ? (
        <h3>Cast a vote</h3>
      ) : (
        <h3>Please log in to cast a vote</h3>
      )}
      {login.loggedin ? (
        <div className="SmishTitleContainer">
          <Button
            disabled={voted}
            onClick={(e) => handleVote("down")}
            variant="contained"
            color="primary"
            className="addSmishTitle"
          >
            No
          </Button>
          <Button
            disabled={voted}
            onClick={(e) => handleVote("up")}
            variant="contained"
            color="primary"
            className="addSmishTitle"
          >
            Yes
          </Button>
        </div>
      ) : (
        <div className="SmishTitleContainer">
          <span className="label-align">&nbsp;</span>
          <Button
            component={Link}
            to="/login"
            variant="contained"
            color="default"
            className="addSmishTitle"
          >
            Login
          </Button>
        </div>
      )}
      {feedback && <span className="label-align">{feedback}</span>}
      {!feedback && <span className="label-align">&nbsp;</span>}
    </div>
  );
}


export {VoteBarChart,VoteButtons};