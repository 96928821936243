import React from 'react';
import Box from '@mui/material/Box'; // Assuming you're using Material-UI

const DisplayBoxItem = ({ item, itemName }) => {
  return (
    <>
      {item ? (
        <Box
          component="div"
          sx={{ whiteSpace: "normal", textAlign: "left", flexGrow: 1 }}
        >
          <div className="msgSection">
            <span className="msgSectionTitle">
              <label>{itemName}:</label>
            </span>
          </div>
          <span className="URLlinkdata">{item}</span>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default DisplayBoxItem;