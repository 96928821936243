import React from "react";
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core'
import ContactPageIcon from '@material-ui/icons/ContactMail'
import { Policy } from "@material-ui/icons";
import { LocalLibrary } from "@material-ui/icons";
import { Info } from "@material-ui/icons";
import { Gavel } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core";

const styles = makeStyles(theme => ({
    menuItem: {
        minWidth: '64px !important',
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.black, 0.10),
        },
        [theme.breakpoints.up('xs')]: {
            padding: "0",
        },
        [theme.breakpoints.up('sm')]: {
            padding: "0px 12px 12px",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1rem !important',
            height: '51px',
        },
        '& .MuiBottomNavigationAction-label': {
            [theme.breakpoints.up('lg')]: {
                fontSize: '1rem !important',
            },
        },
        '& .MuiSvgIcon-root': {
            [theme.breakpoints.up('lg')]: {
                fontSize: '2.0rem !important',
            },
        }
    },
}));

function Footer() {
    const classes = styles()
    return (
        <BottomNavigation style={{bottom:"0"}}
            showLabels
            >
            <BottomNavigationAction className={classes.menuItem} component={Link} to="/contact" label="Contact Us" value="ContactMail" icon={<ContactPageIcon style={{fill: "#3b5998"}}/>} />
            <BottomNavigationAction className={classes.menuItem} component={Link} to="/privacy" label="Privacy Policy" value="privacy" icon={<Policy  style={{fill: "#1DA1F2"}}/>} />
            <BottomNavigationAction className={classes.menuItem} component={Link} to="/about" label="About" value="about" icon={<Info  style={{fill: " #C13584"}}/>} />
            <BottomNavigationAction className={classes.menuItem} component={Link} to="/terms" label="Terms" value="terms" icon={<Gavel  style={{fill: "#3a8e9e"}}/>} /> 
            <BottomNavigationAction className={classes.menuItem} component={Link} to="/learn" label="Learn More" value="learn" icon={<LocalLibrary  style={{fill: "#3b5998"}}/>} />
        </BottomNavigation>
    )
}

export default Footer