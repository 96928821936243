import "./people.css"
import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";

export default function People(props) {
    useEffect(() => {
        document.title = "Smishtank: People"
    }, []);

    return (
        <div className="people">
            <div className="peopleContainer">
               <h2 className="Titleblock">
                People
               </h2>
               <Paper>
                    <div className="pictureCardContainer">
                        <div className="pictureCard">
                            <h2 className="jobTitle">Principal Investigator</h2>
                            <div className="innerPictureCard">
                                <img alt="profile-pic" className="pfpimage" src={require('../../lutfor.jpg')} />
                            </div>
                            <div className="cardText">
                                <div className="nameTitle">
                                    Muhammad Lutfor Rahman, Ph.D
                                </div>
                                <div className="universityText">
                                    <a href="https://faculty.csusm.edu/mlrahman/index.html" className="profLink">
                                        Lead PI and Main Contact
                                        <br />
                                        Assistant Professor at CSUSM
                                    </a>
                                </div>  
                            </div>
                        </div>
                        <div className="pictureCard">
                            <h2 className="jobTitle">Graduate Student</h2>
                            <div className="innerPictureCard">
                                <img alt="profile-pic" className="pfpimage" src={require('../../DanPFP.JPG')} />
                            </div>
                            <div className="cardText">
                                <div className="nameTitle">
                                    Daniel Timko
                                </div>
                                <div className="universityText">
                                    M.S. Computer Science
                                    <br />
                                    California State University San Marcos
                                </div>  
                            </div>
                        </div>
                    </div>
               </Paper>
               
            </div>
        </div>
    )
}