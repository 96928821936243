import "./dataset.css";
import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function Dataset(props) {
  useEffect(() => {
    document.title = "Smishtank: Dataset";
  }, []);

  return (
    <div className="dataset">
      <div className="datasetContainer">
        <h2 className="Titleblock">wisec23data</h2>
        <Paper>
          <h2> Message List</h2>
          <div className="datasetInfoContainer">
            <Document file="Messagelist.pdf">
              <Page pageNumber={1} />
            </Document>
            <h3>
              Table 1: A list of all smishing and benign messages we randomly selected
              for our study. Highlighted are smishing, and non-highlighted are
              benign.
            </h3>
            <br />
            <hr />
            <br />
            <h2> Message Delivery Overview</h2>
            <Document file="bulkmessengersresults.pdf">
              <Page pageNumber={1} />
            </Document>
            <h3>
              Table 2: Overview of messages sent from the bulk messengers to T-Mobile carrier devices.
            </h3>
            <br />
            <hr />
            <br />
            <Document file="carriersmishingblock.pdf">
              <Page pageNumber={1} />
            </Document>
            <h3>
              Table 3: Overview of messages sent to each carrier from Twilio.
            </h3>
            <br />
            <hr />
            <br />
            <Document file="antismishingappblock.pdf">
              <Page pageNumber={1} />
            </Document>
            <h3>
              Table 4: Overview of messages blocked by T-Mobile devices with Anti-smishing apps.
            </h3>
          </div>
        </Paper>
      </div>
    </div>
  );
}
