import "./addSmish.css"
import CustomBtn from '../../components/CustomBtn';
import { Button } from "@material-ui/core";
import { Message, Image, Person } from "@material-ui/icons";
import { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { TextareaAutosize } from '@material-ui/core';
import { getUser } from "../../service/AuthService";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from "@material-ui/core";

// const createPhishUrl = 'https://744td226oe.execute-api.us-west-1.amazonaws.com/prod/createsmish'
const createPhishUrl = 'https://l0sry40g26.execute-api.us-west-1.amazonaws.com/prod/createsmish';
const useStyles = makeStyles((theme) => ({ backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', }, }));

export default function AddSmish(login) {
    useEffect(() => {
        document.title = "Smishtank: Add Smish"
        const getPoster = () => {
            const userName = getUser();
            const posterName = userName !== 'undefined' && userName ? userName.username : '';
            setPoster(posterName);
        }
        getPoster();
    }, []);
    const classes = useStyles();
    const [msgContent, setContent] = useState('');
    const [messageImage, setMessageImage] = useState('');
    const [poster, setPoster] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedImageFile, setImageFile] = useState('');
    const [message, setMessage] = useState(null);
    let messageStyle = {
        color: 'green',
        fontWeight: 'bold'
    };
    if (error) {
        messageStyle = {
            color: 'red',
            fontWeight: 'bold'
        }
    }

    function imageHandler(image) {
        setMessageImage(image.value);
        setImageFile(image.files[0]);
        if (!image.value) {
            setMessageImage('');
            setImageFile('');
        }
    }

    async function readImageFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64Data = event.target.result.split(',')[1]; // Extract the Base64 data
                const jsonData = {
                    image: {
                        contentType: file.type,
                        content: base64Data,
                    },
                };
                resolve(jsonData);
            };

            reader.onerror = (event) => {
                reject(new Error('Error reading the file.'));
            };

            reader.readAsDataURL(file);
        });
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        if (messageImage.trim() === '' && msgContent.trim() === '') {
            setLoading(false);
            setError(true);
            setMessage("An image file or message is required.");
            return;
        }
        if (selectedImageFile !== '') {
            if (selectedImageFile.size > 1000000) {
                setLoading(false);
                setError(true);
                setMessage("The image size is too large");
                return;
            }
            if (selectedImageFile.type !== "image/png" && selectedImageFile.type !== "image/jpeg") {
                setLoading(false);
                setError(true);
                setMessage("Only jpg and png image files are accepted");
                return;
            }
        }
        if (msgContent !== '') {
            if (msgContent.length <= 5) {
                setLoading(false);
                setError(true);
                setMessage("We don't accept messages that short");
            }
        }
        const user = getUser();
        const name = user !== 'undefined' && user ? user.username : '';
        try {
            // Read the image file and get the JSON data


            const requestBody = {
                submitName: name,
            }
            if (selectedImageFile !== '') {
                const jsonData = await readImageFile(selectedImageFile);
                requestBody.image = JSON.stringify(jsonData);
            }

            if (msgContent !== '') {
                requestBody.messageContent = msgContent;
            }

            const requestConfig = {
                headers: {
                    'x-api-key': 'wF6E9Pe3fjUeKALCFfvo9op3yF7dG5K3A4TU94Qf',
                }
            }
            axios.post(createPhishUrl, requestBody, requestConfig).then(response => {
                if (selectedImageFile !== '') {
                    axios.put(response.data.message, selectedImageFile, {
                        method: "PUT",
                        headers: {
                            "Content-Type": selectedImageFile.type
                        }
                    }).then((response) => {
                        setLoading(false);
                        setError(false);
                        setContent("");
                        setImageFile("");
                        setMessageImage("");
                        setMessage("Thank you, the message successfully posted");
                    }, (error) => {
                        setLoading(false);
                        setError(true);
                    });
                } else {
                    setLoading(false);
                    setError(false);
                    setContent("");
                    setImageFile("");
                    setMessageImage("");
                    setMessage("Thank you, the message successfully posted");
                }
            }).catch(error => {
                setLoading(false);
                setError(true);
                if (error.response.status === 401 || error.response.status === 403) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage('The backend server is currently down, please try again later')
                }
            })
        } catch (error) {
            console.error(error.message);
        }

    }

    return (
        <div className="addSmish">
            <div className="SmishFormContainer">
                <span className="addSmishtitle">Submission Instruction: Please capture a screenshot of the phishing SMS, copy its content, upload the screenshot, paste the SMS text, and click the submit button.</span>
                <form onSubmit={submitHandler} className="insertSmishForm" id="submitSmish">
                    <div className="messageInfo">
                        <label className="label-align">
                            <Person className="messageIcon" />
                            <span className="label-align">Submitting As:</span>
                        </label>
                        <Tooltip title="Post anonymously or with your username.">
                            {login.loggedin ? (
                                <label className="label-align">{poster}</label>
                            ) : (
                                <label className="label-align">Anonymous</label>
                            )}
                        </Tooltip>
                    </div>
                    <div className="messageInfo">
                        <label className="label-align">
                            <Image className="messageIcon" />
                            <span className="label-align">Message Screenshot</span>
                        </label>
                        <Tooltip title="We accept images in png or jpg format.">
                            <Button
                                variant="contained"
                                component="label"
                                className="buttonStyle"
                            >
                                Upload Image
                                <input
                                    value={messageImage}
                                    type="file"
                                    accept="image/jpeg,image/png"
                                    placeholder="image path"
                                    className="insertMessageImage"
                                    onChange={event => imageHandler(event.target)}
                                />
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="messageInfo">
                        {messageImage && <span className="label-align">File Path:</span>}
                        {!messageImage && <span className="label-align">&nbsp;</span>}
                        {messageImage && messageImage.split('\\').pop()}
                    </div>
                    <div className="messageInfo">
                        <label className="label-align">
                            <Message className="messageIcon" />
                            <span className="label-align">Message Text</span>
                        </label>
                        <Tooltip title="Please input the full message text here.">
                            <TextareaAutosize
                                value={msgContent}
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Message Contents"
                                className="insertMessageText"
                                onChange={event => setContent(event.target.value)}
                            />
                        </Tooltip>
                    </div>
                    <div className="SmishTitleContainer">
                        <h3 className="addSmishTitle">Add A Smish</h3>
                        <CustomBtn txt="Submit" form="submitSmish" />
                    </div>
                </form>
                {message && <p style={messageStyle}>{message}</p>}
            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}