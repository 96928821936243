import "./contact.css"
import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { Subject } from "@material-ui/icons";
import { ReplySharp } from "@material-ui/icons";

export default function Contact(props) {
    useEffect(() => {
        document.title = "Smishtank: Contact Us"
    }, []);

    return (
        <div className="contact">
            <div className="contactContainer">
               <h2 className="Titleblock">
                    Contact Us
               </h2>
               <Paper>
                    <div className="contactInfoContainer">
                        <h3>Please send questions, comments and feedback to: </h3>
                            
                        <div className="messageInfo">
                            <label>
                                <Email className="messageIcon" />
                                <span className="label-align">Email:</span>
                            </label>
                            <span className="emailText"><a href="mailto:mlrahman@csusm.edu?subject=Smishtank Feedback"><b>mlrahman@csusm.edu</b></a></span>
                        </div>
                        <div className="messageInfo">
                            <label>
                                <Subject className="messageIcon" />
                                <span className="label-align">Subject Line:</span>
                            </label>
                            <span className="subjectText">Smishtank Feedback</span>
                        </div>
                        <hr />
                        <div>
                            <label>
                                <ReplySharp className="messageIcon" />
                                <span className="label-align">We will try to get in touch with your within 48 hours of your email. If you don't hear back from us by then, feel free to send another email</span>
                            </label>
                        </div>
                    </div>
               </Paper>
               
            </div>
        </div>
    )
}