import React from 'react';
import { Grid } from "@material-ui/core";

function WhoisItem({ data, label }) {
  if (data === "Please query the RDDS service of the Registrar of Record  identified in this output for information on how to contact the Registrant, Admin, or Tech contact of the queried domain name.") {
    return null;
  }
  
  return (
    <Grid item xs={12} sm={6} md={4}>
      {data && (
        <div className="whoisResults">{label}</div>
      )}
      {data && (
        <div style={{wordBreak: 'break-all'}}>{data}</div>
      )}
    </Grid>
  );
}

function WhoisItemArray({ data, label}) {
    return (
        <Grid item xs={12} sm={6} md={4}>
          {data && (
            <div className="whoisResults">{label}</div>
          )}
          {data && (
            <div style={{ overflowWrap: 'break-word' }}>{data[0]}</div>
          )}
        </Grid>
      );
}

export { WhoisItem, WhoisItemArray };
export default WhoisItem;