import React from 'react';
import { Paper, Grid } from "@material-ui/core";
// import responseMap from './ResponseCodeMap';
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { WhoisItem } from './WHOISItem';
import DisplayBoxItem from './DisplayBox';

function URLScanResults({ url, urlScanObj }) {
    const [urlScanImageExists, setUrlScanImageExists] = useState(true); // Initialize as false
    const [puppetImageExists, setPuppetImageExists] = useState(false); // Initialize as false
    const { smishId } = useParams();
    useEffect(() => {
        const checkImageStates = async () => {
            let isMounted = true; // Variable to track if the component is mounted
            try {
                if (isMounted) {
                    // await Promise.all([
                    //     checkImageExists(`https://smishtank-direct-upload-s3.s3.us-west-1.amazonaws.com/screenshots/${smishId}_pc`, "urlscan"),
                    //     checkImageExists(`https://smishtank-direct-upload-s3.s3.us-west-1.amazonaws.com/screenshots/${smishId}_mobile`, "puppet")
                    // ]);
                }
            } catch (error) {
                // Handle errors if any
                console.error("Error checking images:", error);
            }
            return () => {
                isMounted = false; // Update the mounted status to false on unmount
            };
        };
        checkImageStates();
    }, [smishId]);
    if (!urlScanObj) {
        return <div>
            <p>URL Information is currently unavailable for the message</p>
        </div>; // If wHOISResults is falsy, render nothing
    }
    async function checkImageExists(imageUrl, type) {
        try {
            const response = await axios.head(imageUrl);
            const imageExists = response.status === 200; // If status is 200, the image exists.
            if (type === "urlscan") {
                setUrlScanImageExists(imageExists);
            }
            else {
                setPuppetImageExists(imageExists);
            }
        } catch (error) {
            if (type === "urlscan") {
                setUrlScanImageExists(false);
            }
            else {
                setPuppetImageExists(false);
            }
        }
    }

    return (
        <div>
            <DisplayBoxItem item={url} itemName="URL" />
            {urlScanObj.page ? (
                <div>
                    <div className="msgSection">
                        <span className="msgSectionTitle">
                            <label>Webpage Information:</label>
                        </span>
                    </div>
                    <Paper className="paperStyling">
                        <Grid container spacing={1}>
                            {Object.entries(urlScanObj.page).map(([key, value]) => (
                                <WhoisItem data={value} label={key} key={key} />
                            ))}
                        </Grid>
                    </Paper>
                    {urlScanImageExists ? (
                        <>
                            <div className="msgSection">
                                <span className="msgSectionTitle">
                                    Web Page Image:
                                </span>
                            </div>
                            <div className="msgInfo smishImg">
                                <img
                                    className="messagePicture"
                                    alt="Web Page Screenshot"
                                    src={`https://smishtank-direct-upload-s3.s3.us-west-1.amazonaws.com/screenshots/${smishId}_pc`}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

export default URLScanResults;