import React from 'react';
import DisplayBoxItem from './DisplayBox';

const DisplayMainSmishContent = ({ foundURL, message }) => {
    function convertUnix(timestamp) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        }).format(timestamp);
    }

    return (
        <div>
            {/* <DisplayBoxItem item={message.submitName} itemName="Submitter"/> */}
            <DisplayBoxItem item={foundURL} itemName="URL"/>
            <DisplayBoxItem item={message.sender} itemName="Sender"/>
            <DisplayBoxItem item={convertUnix(message.timeReceived)} itemName="Time Submitted"/>
            <DisplayBoxItem item={message.brand} itemName="Brand"/>
        </div>
    );
};

export default DisplayMainSmishContent;