import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/home/Home';
import AddSmish from './pages/addsmish/addSmish';
import Smish from './pages/smish/Smish';
import Login from './pages/login/login';
import Register from './pages/register/register';
import PublicRoute from './routes/PublicRoute';
import People from './pages/people/people';
import Dataset from './pages/dataset/dataset';
import Privacy from './pages/privacy/privacy';
import Terms from './pages/terms/terms';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Learn from './pages/learn/learn';
import SmishtankDataset from './pages/smishtankdataset/smishtankdataset';
import { getUser, resetUserSession } from './service/AuthService';
import { useEffect, useState } from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const theme = createTheme ({
  palette: {
    primary: {
      main:"#2e1667",
    },
    secondary: {
      main:"#c7d8ed",
    },
  },
  typography: {
    fontFamily: [
      'Roboto'
    ],
    h4: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '2rem',
      },
    h5: {
      fontWeight: 100,
      lineHeight: '2rem',
    },
  },
});

const styles = makeStyles({
  wrapper: {
    width: "65%",
    margin: "auto",
    textAlign: "center"
  },
  bigSpace: {
    
  },
  littleSpace:{
    
  },
  grid:{
    display: "flex", 
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap", 
  },
})

function App() {
  const classes = styles();
  const user = getUser();
  const name = user !== 'undefined' && user ? user.username : '';
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const logoutHandler = () => {
    resetUserSession();
    setIsLoggedIn(false);
  }
  
  const loginHandler = () => {
    setIsLoggedIn(true);
  }

  useEffect(() => {
      const checkLogin = () => {
          if (name)
          {
              setIsLoggedIn(true);
          }
      }
      checkLogin();
  }, []);

  return (
    <Router>
      <div className="App">
        <ThemeProvider theme={theme}>
          <NavBar
            handleLogout={logoutHandler}
            loggedin={isLoggedIn} />
          <div className="app-content-container">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/addsmish">
                <AddSmish loggedin={isLoggedIn} />
              </Route>
              <Route path="/smish/:smishId">
                <Smish loggedin={isLoggedIn} />
              </Route>
              <PublicRoute path="/login">
                <Login handleLogin={loginHandler} />
              </PublicRoute> 
              <Route path="/register" component={Register} />
              <Route path="/people" component={People} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/learn" component={Learn} />
              <Route path="/terms" component={Terms} />
              <Route path="/wisec23data" component={Dataset} />
              <Route path="/dataset" component={SmishtankDataset} />
            </Switch>
          </div>
          <div className={classes.bigSpace}>
            <Footer />
          </div>
        </ThemeProvider>  
      </div>
    </Router>
  );
}

export default App;