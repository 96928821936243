import React from 'react';
import { Paper, Grid } from "@material-ui/core";
import responseMap from './ResponseCodeMap';
import DisplayBoxItem from './DisplayBox';

function VirusTotalResults({ url, vTResultsObj, VTTextColor }) {
    if (!vTResultsObj) {
        return <div>
            <p>Virus Information is currently unavailable for the message</p>
        </div>; // If wHOISResults is falsy, render nothing
    }
    let responseDetails = null;
    if (vTResultsObj.responseCode && responseMap[vTResultsObj.responseCode]) {
        responseDetails = responseMap[vTResultsObj.responseCode];
    } else {
        responseDetails = { status: null, description: 'Response code not found' };
    }
    return (
        <div>
            <DisplayBoxItem item={url} itemName="Scanned URL" />
            <DisplayBoxItem item={vTResultsObj.finalURL} itemName="URL Final Destination" />
            <DisplayBoxItem item={responseDetails.status} itemName={`Response Code: ${vTResultsObj.responseCode}`} />
            <DisplayBoxItem item={vTResultsObj.redirectIP} itemName="redirect IP" />
            <div className="msgSection">
                <span className="msgSectionTitle">
                    <label>VirusTotal Results:</label>
                </span>
            </div>
            {vTResultsObj ? (
                <Paper className="paperStyling">
                    <Grid container spacing={1}>
                        <Grid item className="vtResults" xs={6} md={12}>
                            <div style={{ color: VTTextColor }}>Detected</div>
                            <div>{vTResultsObj.detected}</div>
                        </Grid>
                        <Grid item className="vtResults" xs={6} md={4}>
                            <div>Malicious</div>
                            <div>{vTResultsObj.malicious}</div>
                        </Grid>
                        <Grid item className="vtResults" xs={6} md={4}>
                            <div>Malware</div>
                            <div>{vTResultsObj.malware}</div>
                        </Grid>
                        <Grid item className="vtResults" xs={6} md={4}>
                            <div>Phishing</div>
                            <div>{vTResultsObj.phishing}</div>
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                ""
            )}
        </div>
    );
}

export default VirusTotalResults;